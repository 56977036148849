import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-realisation-dialog-component',
  templateUrl: './realisation-dialog-component.component.html',
  styleUrls: ['./realisation-dialog-component.component.scss']
})
export class RealisationDialogComponentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageSrc: string }) {}
}
