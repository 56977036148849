<footer>
    <div class="logo-container">
        <div class="logo">MAISON SEVERI</div>
        <div class="description">Intérieurs sur-mesure.</div>
    </div>
    <div class="sitemap">
        <a href="/">Accueil</a>
        <a href="/a-propos">À propos</a>
        <a href="/prestations">Nos prestations</a>
        <a href="/realisations">Nos réalisations</a>
        <a href="/contact">Nous contacter</a>
    </div>
    <div class="baseline">Made with ❤ by <a href="https://paulmazeau.fr">Paul Mazeau</a></div>
</footer>