<app-menu></app-menu>
<div class="main-container">
    <div class="top-wrapper">
        <div class="left-container">
            <div class="title">Contactez-nous !</div>
            <div class="description">Envie de repenser votre intérieur ?</div>
            <div class="description">Si vous souhaitez repenser votre intérieur, n’hésitez pas à nous contacter afin de déterminer quels sont les solutions adaptées à votre projet.</div>
            <a href="tel:0675873058" class="phone-number">06 14 80 20 73</a>
            <a href="mailto:contact@maisonseveri.fr" class="email"> contact@maisonseveri.fr </a>
        </div>
        <div class="right-container">
            <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
                <div class="form-group">
                  <label for="name">Nom / Prénom *</label>
                  <input type="text" class="form-control" id="name" name="name" ngModel [required]="true">
                  <div *ngIf="contactForm.controls['name']?.invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)" class="text-danger">
                    <div *ngIf="contactForm.controls['name'].hasError('required')" style="color:red">Ce champ est obligatoire.</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="email">Adresse e-mail *</label>
                  <input type="email" class="form-control" id="email" name="email" [(ngModel)]="email" [required]="true" [email]="true">
                  <div *ngIf="contactForm.controls['email']?.invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)" class="text-danger">
                    <div *ngIf="contactForm.controls['email'].hasError('required')" style="color:red">L'adresse email est obligatoire.</div>
                    <div *ngIf="contactForm.controls['email'].hasError('email')" style="color:red">L'adresse email doit être valide.</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="subject">Téléphone *</label>
                  <input type="tel" class="form-control" id="phone" name="phone" ngModel required pattern="^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$">
                  <div *ngIf="contactForm.controls['phone']?.invalid && (contactForm.controls['phone'].dirty || contactForm.controls['phone'].touched)" class="text-danger">
                    <div *ngIf="contactForm.controls['phone'].hasError('required')" style="color:red">Le numéro de téléphone est obligatoire.</div>
                    <div *ngIf="contactForm.controls['phone'].hasError('phone')" style="color:red">Le numéro de téléphone doit être valide.</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="subject">Projet *</label>
                  <input type="text" class="form-control" id="project" name="project" ngModel required>
                  <div *ngIf="contactForm.controls['project']?.invalid && (contactForm.controls['project'].dirty || contactForm.controls['project'].touched)" class="text-danger">
                    <div *ngIf="contactForm.controls['project'].hasError('required')" style="color:red">Ce champ est obligatoire.</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="message">Description *</label>
                  <textarea class="form-control" id="description" name="description" ngModel required></textarea>
                  <div *ngIf="contactForm.controls['description']?.invalid && (contactForm.controls['description'].dirty || contactForm.controls['description'].touched)" class="text-danger">
                    <div *ngIf="contactForm.controls['description'].hasError('required')" style="color:red">Ce champ est obligatoire.</div>
                  </div>
                </div>
                <button type="submit" [disabled]="!contactForm.valid" class="btn btn-primary">Envoyer</button>
              </form>
        </div>
    </div>
    <div class="number">05</div>
</div>
<app-footer></app-footer>