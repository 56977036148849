import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RealizationsService {

  constructor() { }

  realizations = [
    { 
      image: "/assets/images/realisations/image_01.jpg", 
      title: 'Appart 01', 
      category: 'Architecture d\'intérieur', 
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      portfolio: [
        "/assets/images/realisations/image_01.jpg",
        "/assets/images/realisations/image_01.jpg",
        "/assets/images/realisations/image_01.jpg",
        "/assets/images/realisations/image_01.jpg"
      ]
    },
    { 
      image: "/assets/images/realisations/image_02.jpg", 
      title: 'Appart 02',
      category: 'Architecture d\'intérieur',
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      portfolio: [
        "/assets/images/realisations/image_02.jpg",
        "/assets/images/realisations/image_02.jpg",
        "/assets/images/realisations/image_02.jpg",
        "/assets/images/realisations/image_02.jpg"
      ]
    },
    { 
      image: "/assets/images/realisations/image_03.jpg",
      title: 'Appart 03',
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      category: 'Décoration d\'intérieur', 
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      focus: true, 
      portfolio: [
        "/assets/images/realisations/image_03.jpg",
        "/assets/images/realisations/image_03.jpg",
        "/assets/images/realisations/image_03.jpg",
        "/assets/images/realisations/image_03.jpg"
      ]
    },
    { 
      image: "/assets/images/realisations/image_04.jpg", 
      title: 'Appart 04', 
      category: 'Architecture d\'intérieur', 
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      portfolio: [
        "/assets/images/realisations/image_04.jpg",
        "/assets/images/realisations/image_04.jpg",
        "/assets/images/realisations/image_04.jpg",
        "/assets/images/realisations/image_04.jpg"
      ]
    },
      { 
      image: "/assets/images/realisations/image_05.jpg", 
      title: 'Appart 05', 
      category: 'Architecture d\'intérieur', 
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      portfolio: [
        "/assets/images/realisations/image_05.jpg",
        "/assets/images/realisations/image_05.jpg",
        "/assets/images/realisations/image_05.jpg",
        "/assets/images/realisations/image_05.jpg"
      ]
    },
    { 
      image: "/assets/images/realisations/image_06.jpg", 
      title: 'Appart 06', 
      category: 'Modeling 3D', 
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!", 
      longDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad possimus dolorem reiciendis iure unde alias ex a expedita quas quam. Voluptas, nihil beatae. Sequi, tempore optio deleniti magnam numquam sapiente. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quidem temporibus, doloribus ut reprehenderit voluptate illum ratione a, provident, possimus sunt pariatur. Quae iste cupiditate beatae fuga voluptatibus ab qui!",
      portfolio: [
        "/assets/images/realisations/image_06.jpg",
        "/assets/images/realisations/image_06.jpg",
        "/assets/images/realisations/image_06.jpg",
        "/assets/images/realisations/image_06.jpg"
      ]
    },
  ];
}
