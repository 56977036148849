<div class="main-container">
    <div class="left-container">
        <div class="topline">INTERIEUR</div>
        <div class="title">Architecture</div>
        <div class="description">L’architecture d’intérieur est un service haute couture qui se résume par une seule et même philosophie : créer, à partir de volumes existants, des intérieurs sur-mesure. Le champ des possibilités étant infini, le designer d’espace doit alors trouver l’équilibre parfait entre l’Homme et la surface tout en considérant l’esthétique, la fonctionnalité et la durabilité.</div>
        <a routerLink="/prestations" class="services-cta">En savoir plus</a>
        <div class="number">01</div>
    </div>
    <div class="menu-container">
        <div class="divider one"></div>
        <a class="menu-link" href="/a-propos">À propos</a>
        <a class="menu-link" href="/realisations">Nos réalisations</a>
        <a class="menu-logo" href="/">MAISON SEVERI</a>
        <a class="menu-link" href="prestations">Nos prestations</a>
        <a class="menu-link" href="contact">Nous contacter</a>
        <div class="divider two"></div>
    </div>
    <div class="menu-container-mobile" [class.active]="active">
        <div class="burger-menu" [class.active]="active" (click)="onBurgerClicked()">
            <div class="burger-container">
              <div class="burger-inner" [ngClass]="active ? 'dark' : 'light'"></div>
            </div>
          </div>
          <div class="menu-content" *ngIf="active">
            <a class="menu-link" href="/">Accueil</a>
            <a class="menu-link" href="/a-propos">À propos</a>
            <a class="menu-link" href="/realisations">Nos réalisations</a>
            <a class="menu-link" href="prestations">Nos prestations</a>
            <a class="menu-link" href="contact">Nous contacter</a>
          </div>
    </div>
</div>
<div class="realizations-container">
    <div class="wrapper">
        <img src="../../assets/images/LOGO.PNG.png" alt="" class="logo">
        <div class="description">Création - Innovation - Fonctionnalité</div>
        <div class="image-wrapper">
            <img *ngFor="let realization of realizations; let i = index" src="{{ realization.image }}"
                class="realization-{{i}}">
        </div>
        <a class="cta-realizations" href="/realisations">Voir toutes nos réalisations</a>
    </div>
</div>
<div class="realization-focus-container">
    <img src="{{realizationFocus.image}}" alt="">
    <div class="right-wrapper">
        <div class="title">{{realizationFocus.title}}</div>
        <div class="description">{{realizationFocus.description}}</div>
        <a class="cta-realizations" href="/realisations">Voir toutes nos réalisations</a>
    </div>
</div>
<div class="prestations-container">
    <div class="title">Nos prestations</div>
    <div class="description">Nous proposons divers services tels que la production de plans, la création de planches tendances, ou encore la modélisation de votre projet en 3D.</div>
    <div class="prestations-container">
        <div *ngFor="let prestation of prestations; let i = index" class="prestation prestation-{{i}}">
            <img src="{{prestation.image}}" alt="">
            <div class="prestation-title">{{prestation.title}}</div>
            <div class="prestation-type">{{prestation.type}}</div>
        </div>
    </div>
    <div class="cta-text">Prêt à donner vie à votre projet architectural ? Notre équipe passionnée est là pour vous accompagner. <br> Contactez-nous dès maintenant pour discuter de vos idées, obtenir des conseils d'experts et commencer à réaliser votre vision architecturale. <br> <br> Cliquez sur le bouton ci-dessous pour nous contacter.</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
</div>
<div class="contact-container">
    <div class="shade"></div>
    <div class="description">"L'architecture est un jeu savant, correct et magnifique des volumes assemblés sous la lumière" - Le Corbusier</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
</div>
<app-footer></app-footer>