import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*'
    })
  };

  constructor(private http: HttpClient) { }

  sendEmail(name: string, fromClient: string, phone: string, description: string, project: string, token: string) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('fromClient', fromClient);
    formData.append('phone', phone);
    formData.append('project', project);
    formData.append('description', description);
    formData.append('recaptchaResponse', token);
    
    return this.http.post('/api/email', formData);
  }
}