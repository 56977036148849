import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RealizationsService } from '../realizations.service';
import { PrestationsService } from '../prestations.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  realizations: any[];
  realizationFocus: any;
  prestations: any[];

  @Input() init: boolean | undefined;
  @Output() opened = new EventEmitter<any>();

  active = false;

  ngOnInit() {
    this.active = this.init || false;
  }

  onBurgerClicked() {
    this.active = !this.active;
    this.opened.emit();
  }

  constructor(
    private realizationsService: RealizationsService,
    private prestationsService: PrestationsService
    ) {
      this.realizations = this.realizationsService.realizations.slice(0, 6); // ne prendre que les 6 premieres realisations pour les afficher sur la home page
      this.prestations = this.prestationsService.prestations;
      
      this.realizations.forEach(real => {
        if(real.focus) this.realizationFocus = real;
      }); 
  }

  
}
