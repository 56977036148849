<app-menu></app-menu>
<div class="main-container">
    <div class="carousel-container">
        <carousel-realizations [slides]="realizationsSorted" [uniqueCategories]="uniqueCategories"></carousel-realizations>
    </div>
    <div class="number">03</div>
</div>
<div class="main-realisation-container">
    <div class="title">Toutes nos réalisations</div>
    <div class="description">Chez MAISON SEVERI, nous aimons travailler avec des matériaux bruts tels que la pierre et le bois, en les associants à des teintes naturelles, pour créer l’harmonie parfaite et ainsi investir une notion d’intemporalité dans l’espace.</div>
    <div class="realisations-wrapper">
        <div class="realisation" *ngFor="let realisation of realisations" [class.hover-style]="isHovered === realisation.title" 
        (mouseover)="isHovered = realisation.title" (mouseleave)="isHovered = null">
            <img src={{realisation.image}} alt="">
            <div class="image-container">
                <div class="realisation-category">{{realisation.category}}</div>
                <div class="realisation-title">{{realisation.title}}</div>
                <a class="cta-realisation" href="{{realisation.link}}">VOIR</a>
            </div>
        </div>
    </div>
</div>
<div class="contact-container">
    <div class="shade"></div>
    <div class="description">"L'architecture est un jeu savant, correct et magnifique des volumes assemblés sous la lumière" - Le Corbusier</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
    </div>
<app-footer></app-footer>