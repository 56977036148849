<app-menu></app-menu>
<div class="main-container">
    <div class="carousel-container">
        <carousel [slides]="prestations"></carousel>
    </div>
    <div class="number">04</div>
</div>
<div class="main-prestations-container">
    <div class="title">Toutes nos prestations</div>
    <div class="description">Chez MAISON SEVERI, nous aimons travailler avec des matériaux bruts tels que la pierre et le bois, en les associants à des teintes naturelles, pour créer l’harmonie parfaite et ainsi investir une notion d’intemporalité dans l’espace.</div>
    <div class="prestations-wrapper">
        <div class="prestation" id="architecture">
            <div class="image-container">
                <img src="/assets/images/realisations/image_10.jpg" alt="">
            </div>
            <div class="right-wrapper">
                <div class="title-prestation">Architecture d'intérieur</div>
                <div class="description-prestation">Afin d'aborder tout l'aspect technique d'un projet, nous passons par plusieurs phases de production de plans. Allant du plan de principe au plan technique, Maison Severi aménage et rénove les espaces en créant des visuels 2D complets.</div>
                <div class="process-structure"></div>
                <div class="cta"><a href="/contact">Nous contacter</a> pour effectuer un devis sur mesure</div>
            </div>
            <div class="empty-div"></div>
        </div>
        <div class="prestation inversed-prestation" id="decoration">
            <div class="empty-div"></div>
            <div class="right-wrapper">
                <div class="title-prestation">Décoration d'intérieur</div>
                <div class="description-prestation">La décoration étant en évolution perpétuelle à travers les époques, notre cabinet propose une notion d'intemporalité notamment à travers le choix des matériaux ou encore des formes. En intégrant la notion de Feng Shui, nous créons des planches d'inspirations réunissant les tendances et les attentes du client en terme de style.</div>
                <div class="process-structure"></div>
                <div class="cta"><a href="/contact">Nous contacter</a> pour effectuer un devis sur mesure</div>
            </div>
            <div class="image-container inversed">
                <img src="/assets/images/realisations/image_11.jpg" alt="">
            </div>        </div>
        <div class="prestation" id="modeling">
            <div class="image-container">
                <img src="/assets/images/realisations/image_08.jpg" alt="">
            </div>            
            <div class="right-wrapper">
                <div class="title-prestation">Modeling 3D</div>
                <div class="description-prestation">La modélisation 3D permet une approche plus réaliste et concrète d'un projet. En effet, elle permet de mettre en scène les matériaux, les teintes et le mobilier choisi.</div>
                <div class="process-structure"></div>
                <div class="cta"><a href="/contact">Nous contacter</a> pour effectuer un devis sur mesure</div>
            </div>
            <div class="empty-div"></div>
        </div>
    </div>
</div>
<div class="contact-container">
    <div class="shade"></div>
    <div class="description">"L'architecture est un jeu savant, correct et magnifique des volumes assemblés sous la lumière" - Le Corbusier</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
</div>
<app-footer></app-footer>