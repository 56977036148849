import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactService } from '../contact-service.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  email: any;

  constructor(
    private contactService: ContactService,
    private recaptchaV3Service: ReCaptchaV3Service,
    ) {}

  onSubmit(contactForm: NgForm) {
    const name = contactForm.value.name;
    const fromClient = contactForm.value.email;
    const phone = contactForm.value.phone;
    const project = contactForm.value.project;
    const description = contactForm.value.description;
  
      this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        this.contactService.sendEmail(name, fromClient, phone, description, project, token).subscribe(
          () => {
            console.log('Email envoyé avec succès.');
            Swal.fire({
              icon: 'success',
              title: 'Confirmation',
              text: `Votre message a bien été envoyé ! Nous reviendrons vers vous dans les plus brefs délais.`,
              confirmButtonColor: "#8A9285"
            })
            contactForm.reset();
          },
          (error: any) => {
            console.error('Erreur lors de l\'envoi de l\'email :', error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `Une erreur est survenue lors de l'envoi de votre message. Veuillez réessayer dans quelques instants.`,
              confirmButtonColor: "#8A9285"
            })
          }
        );
        
      });
    }

    
  }