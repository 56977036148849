import { Component } from '@angular/core';
import { PrestationsService } from '../prestations.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  prestations: any[];

  constructor(
    private prestationsService: PrestationsService
  ){
    this.prestations = this.prestationsService.prestations;
  }
}
