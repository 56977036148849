import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RealizationsService } from '../realizations.service';
import {  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RealisationDialogComponentComponent } from '../realisation-dialog-component/realisation-dialog-component.component';

@Component({
  selector: 'app-realisation-details',
  templateUrl: './realisation-details.component.html',
  styleUrls: ['./realisation-details.component.scss']
})
export class RealisationDetailsComponent {
  realisationSelected:any;
  realisations: any;
  realisationToDisplay: any;
  afterViewInitDone = false;

  constructor(
    private route: ActivatedRoute,
    private realizationsService: RealizationsService,
    public dialog: MatDialog
    ){
      this.realisationSelected = this.route.snapshot.paramMap.get('id');
      this.realisations = this.realizationsService.realizations;
      this.realisationToDisplay = this.realisations.find((realisation: any) => realisation.title.replace(/\s+/g, '-').toLowerCase() === this.realisationSelected);
      this.afterViewInitDone = true;
    }

    openDialog(img:any) {
      const dialogRef = this.dialog.open(RealisationDialogComponentComponent, {
        data: { imageSrc: img },
        width: 'auto',
        height: 'auto',
        panelClass: 'custom-dialog-container'
      });
    }
}
