<app-menu></app-menu>
<div class="main-container">
    <div class="top-wrapper">
        <div class="left-container">
            <img src="{{realisationToDisplay.image}}" alt="" class="profile-picture">
        </div>
        <div class="right-container">
            <div class="title">{{realisationToDisplay.title}}</div>
            <div class="description">{{realisationToDisplay.description}}</div>
        </div>
        <div class="controls-container">
            <span class="arrow left"></span>
            <a href="/realisations" class="text">Retour</a>
            <div class="style-line"></div>
        </div>
    </div>
    <div class="number">03</div>
</div>
<div class="details-container">
    <div class="description">{{realisationToDisplay.longDescription}}</div>
    <div class="images-container">
        <img src="{{image}}" alt="" *ngFor="let image of realisationToDisplay.portfolio">
    </div>
</div>
<div class="contact-container">
    <div class="shade"></div>
    <div class="description">"L'architecture est un jeu savant, correct et magnifique des volumes assemblés sous la lumière" - Le Corbusier</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
</div>
<app-footer></app-footer>