<div class="carousel">
    <ng-container *ngFor="let slide of slides[currentCat]; let i = index">
      <div class="image-wrapper">
        <img
          *ngIf="i === currentSlide"
          [src]="slide.image"
          class="slide"
          @carouselAnimation
        />
        <a class="see-more-tag" *ngIf="i === currentSlide" href="{{slide.link}}">En savoir plus</a>
      </div>
      <div class="right-panel" *ngIf="i === currentSlide">
        <div class="title">{{slide.title}}</div>
        <div class="description">{{slide.description}}</div>
        <a href="{{slide.link}}">En savoir plus</a>
      </div>
    </ng-container>
    <div class="categories">
        <div class="category" 
            *ngFor="let category of uniqueCategories; let i = index" 
            [ngClass]="{'activeSlide': i === currentCat}"
            (click)="goToCategory(i)"
        >{{category}} - {{slides[i].length}}</div>
    </div>
    <a class="see-more-category" (click)="seeMoreOpen()">Voir davantage</a>
    <div class="indicator-container">
        <div class="indicator"
            *ngFor="let slide of slides[currentCat]; let i = index" 
            [ngClass]="{'activeIndicator': i === currentSlide}"
            (click)="goToPrecise(i)"
        ></div>
    </div>
  
    <!-- controls -->
    <div class="controls-container">
        <button class="control prev" (click)="onPreviousClick()">
            <span class="arrow left"></span>
        </button>
        <div class="style-line"></div>
        <div class="text" (click)="onNextClick()">Suivant</div>
        <button class="control next" (click)="onNextClick()">
          <span class="arrow right"></span>
        </button>

    </div>
  </div>

  <div class="categories-modal" [ngClass]="{'display-responsive': seeMoreClicked}">
    <div class="wrapper">
      <div class="category" 
          *ngFor="let category of uniqueCategories; let i = index" 
          [ngClass]="{'activeSlide': i === currentCat}"
          (click)="goToCategory(i)"
      >{{category}} - {{slides[i].length}}</div>
      <div class="croix" (click)="seeMoreClose()">
        <div class="ligne1"></div>
        <div class="ligne2"></div>
      </div>
    </div>
  </div>