<app-menu></app-menu>
<div class="main-container">
    <div class="left-container">
        <img src="../../assets/images/LOGO.PNG.png" alt="" class="logo">
        <div class="description">
            <div class="first-line">Originaire du Sud de la France, Carla Severi est une jeune architecte d’intérieur passionnée par la création et la décoration. C’est en 2022 qu’elle crée Maison Severi, un studio à l’image de son intérêt pour l’architecture.</div>
            <div class="second-line">Maison Severi se spécialise dans la combinaison d’un style japandi et bohème chic raffiné, pour créer des espaces chaleureux et conviviaux, dans l’air du temps. Ainsi, en utilisant des matériaux naturels et durables dans ses créations, Carla donne un caractère intemporel aux espaces de vie.</div>
        </div>
        <a routerLink="/contact" class="contact-cta">Nous contacter</a>
        <div class="number">02</div>
    </div>
    <div class="right-container">
        <div class="images-wrapper">
            <div class="decoration-rectangle"></div>
            <img src="../../assets/images/random/carla-profile.png" alt="" class="profile-picture">
            <img src="../../assets/images/svg/three-lines.svg" alt="" class="three-lines">
        </div>
    </div>
</div>
<div class="prestations-container">
    <div class="wrapper">
        <div class="left-container">
            <img *ngFor="let prestation of prestations; let i = index" src="{{prestation.image}}" class="prestation prestation-{{i}}">
        </div>
        <div class="right-container">
            <div class="title">Nos prestations</div>
            <div class="description">Nous proposons divers services tels que la production de plans, la création de planches tendances, ou encore la modélisation de votre projet en 3D.</div>
            <a class= "cta-prestations" href="/prestations">Voir toutes nos prestations</a>
        </div>
    </div>
</div>
<div class="contact-container">
    <div class="shade"></div>
    <div class="description">"L'architecture est un jeu savant, correct et magnifique des volumes assemblés sous la lumière" - Le Corbusier</div>
    <a class="cta-contact" href="/contact">Nous contacter</a>
</div>
<app-footer></app-footer>