import { Component } from '@angular/core';
import { PrestationsService } from '../prestations.service';

@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.scss']
})
export class PrestationsComponent {
  prestations: any;

  constructor(
    private prestationsService: PrestationsService
  ){
    this.prestations = this.prestationsService.prestations;
  }
  
}
