import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { RealizationsService } from '../realizations.service';

@Component({
  selector: 'app-realisations',
  templateUrl: './realisations.component.html',
  styleUrls: ['./realisations.component.scss']
})

export class RealisationsComponent {
  realisations: any;
  categories: any;
  uniqueCategories: any;
  realizationsSorted: any;
  isHovered: number | null = null;

  constructor(
    private realizationsService: RealizationsService
  ){
    this.realisations = this.realizationsService.realizations;
    this.realisations.forEach((realisation: any) => {
      let linkedTitle = realisation.title.replace(/\s+/g, '-').toLowerCase();
      realisation.link = '/realisations/' + linkedTitle;
    })
    this.categories = this.realisations.map((obj: any) => obj.category);
    this.uniqueCategories = new Set(this.categories);

    const groupedCategories = this.realisations.reduce((acc: { [x: string]: any[]; }, category: { [x: string]: any; id: any; }) => {
      const { ...rest } = category;
      const { category: categoryName } = rest;
      console.log("acc", acc)
    
      if (!acc[categoryName]) {
        acc[categoryName] = [rest];
      } else {
        acc[categoryName].push(rest);
      }
    
      return acc;
    }, {});
    
    const category1Objects = groupedCategories["Architecture d'intérieur"];
    const category2Objects = groupedCategories["Décoration d'intérieur"];
    const category3Objects = groupedCategories["Modeling 3D"];
    this.realizationsSorted = [];
    this.realizationsSorted.push(category1Objects)
    this.realizationsSorted.push(category2Objects)
    this.realizationsSorted.push(category3Objects)
  }
}
