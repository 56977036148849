import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { PrestationsComponent } from './prestations/prestations.component';
import { RealisationDetailsComponent } from './realisation-details/realisation-details.component';
import { RealisationsComponent } from './realisations/realisations.component';

const routes: Routes = [
  { path: 'accueil', component: HomeComponent },
  { path: 'a-propos', component: AboutComponent },
  { path: 'realisations', component: RealisationsComponent },
  { path: 'prestations', component: PrestationsComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', component: HomeComponent },
  { path: 'realisations/:id', component: RealisationDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
