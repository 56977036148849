<div class="menu-container">
    <div class="item-container">
        <a class="menu-link" href="/a-propos">À propos</a>
        <a class="menu-link" href="/realisations">Nos réalisations</a>
        <a class="menu-logo" href="/">MAISON SEVERI</a>
        <a class="menu-link" href="prestations">Nos prestations</a>
        <a class="menu-link" href="contact">Nous contacter</a>
    </div>
</div>

<div class="menu-container-mobile" [class.active]="active">
    <div class="burger-menu" [class.active]="active" (click)="onBurgerClicked()">
        <div class="burger-container">
          <div class="burger-inner" [ngClass]="active ? 'dark' : 'light'"></div>
        </div>
      </div>
      <div class="menu-content" *ngIf="active">
        <a class="menu-link" href="/">Accueil</a>
        <a class="menu-link" href="/a-propos">À propos</a>
        <a class="menu-link" href="/realisations">Nos réalisations</a>
        <a class="menu-link" href="prestations">Nos prestations</a>
        <a class="menu-link" href="contact">Nous contacter</a>
      </div>
</div>