import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrestationsService {

  constructor() { }

  prestations = [
    { image: "/assets/images/realisations/image_10.jpg", title: "Architecture d'interieur", link: "/prestations#architecture", type: "Rénovation & suivi", description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quos iusto officia hic quas. Provident perferendis ipsam sequi? Tempora porro reprehenderit magni reiciendis iste asperiores ipsam possimus debitis dolores officiis.", subdescription: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quos iusto officia hic quas. Provident perferendis ipsam sequi? Tempora porro reprehenderit magni reiciendis iste asperiores ipsam possimus debitis dolores officiis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quos iusto officia hic quas. Provident perferendis ipsam sequi? Tempora porro reprehenderit magni reiciendis iste asperiores ipsam possimus debitis dolores officiis." },
    { image: "/assets/images/realisations/image_11.jpg", title: "Décoration d'interieur", link: "/prestations#decoration", type: "Conception & suivi", description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quos iusto officia hic quas. Provident perferendis ipsam sequi? Tempora porro reprehenderit magni reiciendis iste asperiores ipsam possimus debitis dolores officiis."},
    { image: "/assets/images/realisations/image_08.jpg", title: "Modeling 3D", link: "/prestations#modeling", type: "Conception & suivi", description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum quos iusto officia hic quas. Provident perferendis ipsam sequi? Tempora porro reprehenderit magni reiciendis iste asperiores ipsam possimus debitis dolores officiis."},
  ];
}
