import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { RealisationsComponent } from './realisations/realisations.component';
import { PrestationsComponent } from './prestations/prestations.component';
import { ContactComponent } from './contact/contact.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselRealizationsComponent } from './carousel-realizations/carousel-realizations.component';
import { RealisationDetailsComponent } from './realisation-details/realisation-details.component';
import { RealisationDialogComponentComponent } from './realisation-dialog-component/realisation-dialog-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    MenuComponent,
    FooterComponent,
    RealisationsComponent,
    PrestationsComponent,
    ContactComponent,
    CarouselComponent,
    CarouselRealizationsComponent,
    RealisationDetailsComponent,
    RealisationDialogComponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaV3Module
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeWCoYlAAAAALJbpRBxnAtJ3JZCMMsAXXvyEaH-" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
